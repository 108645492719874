import { CopilotFeatureName, CopilotAshaError } from "../constants/CopilotConstants";
import { CreateEntitiesScenarios, ModifyEntitiesScenarios, OdslFunctionToScenarioMapping, CopilotFeatureToVerbMapping, CopilotAshaVeto } from "../constants/CopilotConstants";
import { CopilotUsageLogger } from "../loggers/CopilotUsageLogger";
import { SharedChatLogger } from "../loggers/SharedChatLogger";
import { AshaLogger } from "../loggers/AshaLogger";
import { TraceLevel } from "@ms/uno-telemetry/lib/local/events/Trace.event";
/** The name of the QoS event */ const QOS_NAME = "queryPlannerAppCopilot";
/**
 * This class handles the logging of Copilot events in accordance with the DIG/Copilot telemetry guidelines.
 * See https://www.owiki.ms/wiki/DIG/Copilot#When_to_log_the_telemetry_events_for_Seen/_Tried/_Kept
 */ export class CopilotTelemetryHandler {
    /** Get the singleton instance of CopilotTelemetryHandler */ static getInstance() {
        if (!this.instance) {
            this.instance = new CopilotTelemetryHandler();
        }
        return this.instance;
    }
    /**
     * Set up the logger for Copilot telemetry events
     * @param {ILogHandler} logHandler The log handler to use for logging
     */ setupLogger(logContext) {
        this.logContext = logContext;
        this.usageLogger = new CopilotUsageLogger(logContext.logHandler);
        this.ashaLogger = new AshaLogger(logContext.logHandler);
        this.sharedChatLogger = new SharedChatLogger(logContext.logHandler);
    }
    /**
     * Log a telemetry event
     * @param {TelemetryLog} telemetryLog The telemetry event to log
     */ LogTelemetryEvent(telemetryLog) {
        this.sharedChatLogger?.LogTelemetryEvent(telemetryLog);
    }
    /**
     * Log a telemetry event
     */ logEnabledFeatures(enabledFeatures) {
        // skip logging if the logger has not been set up
        if (!this.usageLogger) {
            this.logContext?.traceLogger.logTrace(0x1e29a4d1 /* tag_4k0tr */ , TraceLevel.Error, "CopilotTelemetryHandler: setupLogger was never called?");
        }
        // log once per session per feature
        for (const feature of enabledFeatures){
            // skip logging if the feature has already been logged
            if (!this.loggedEnabledFeatures.has(feature)) {
                this.usageLogger?.logEnabled(feature);
                this.loggedEnabledFeatures.add(feature);
            }
        }
    }
    /**
     * Log a telemetry event
     * @param {CopilotFeatureName} feature The feature that was seen
     * @return {*}  {void}
     */ logSeenEvent(feature) {
        // log once per session per feature
        if (this.loggedSeenFeatures.has(feature)) {
            return;
        }
        this.usageLogger?.logSeen(feature);
        this.loggedSeenFeatures.add(feature);
    }
    /**
     * Log a telemetry event
     * @param {string} queryId The Augloop query ID
     */ startTriedEvent(queryId) {
        // if there is a pending tried event, log it
        if (this.pendingTriedEvent) {
            this.logContext?.traceLogger.logTrace(0x1e29a4d0 /* tag_4k0tq */ , TraceLevel.Error, `${this.name} - startTriedEvent called before a pending tried event was ended.`);
            // Log the unfinished event. However, skip the QosEvent as its duration and resultType cannot be accurately determined.
            this.logTriedEvent(this.pendingTriedEvent);
        }
        // start a new QosEvent
        const qosEvent = this.logContext?.engagementLogger?.startQos(this.name, QOS_NAME);
        if (qosEvent) {
            // update the pending tried event
            this.pendingTriedEvent = {
                queryId,
                qosEvent,
                featureName: CopilotFeatureName.CopilotChat
            };
        }
    }
    /**
     * Update the pending tried event with the feature and sub-feature names from the ODSL response
     * @param {string} queryId The Augloop query ID
     * @param {ICopilotOdsl} odslResponse The ODSL response
     */ updateTriedEventForOdslResponse(queryId, odslResponse) {
        // if there is no pending tried event, return
        if (!odslResponse.odsl) {
            return;
        }
        // Do not log sub-features for build plan
        if (this.pendingTriedEvent?.featureName === CopilotFeatureName.BuildPlan) {
            return;
        }
        // get the scenarios from the ODSL response
        const scenarios = this.getScenariosFromOdslStatements(odslResponse.odsl.statements);
        // using the scenarios to determine the feature name
        const featureName = this.getFeatureNameFromScenarios(scenarios);
        // using the combined scenarios as the subFeatureName
        const subFeatureName = Array.from(scenarios).sort().join(",");
        // update the pending tried event with the feature and sub-feature names
        this.updateTriedEventForFeature(queryId, featureName, subFeatureName);
    }
    /**
     * Update the pending tried event with the feature and sub-feature names
     * @param {string} queryId The Augloop query ID
     * @param {CopilotFeatureName} featureName The feature name
     * @param {string} [subFeatureName] The sub-feature name
     */ updateTriedEventForFeature(queryId, featureName, subFeatureName) {
        // if there is no pending tried event, return
        if (this.pendingTriedEvent?.queryId !== queryId) {
            this.logContext?.traceLogger.logTrace(0x1e299291 /* tag_4kzkr */ , TraceLevel.Error, `${this.name} - updateTriedEventForFeature called with unexpected queryId.`);
            return;
        }
        // update the pending tried event with the feature and sub-feature names
        this.pendingTriedEvent.featureName = featureName;
        this.pendingTriedEvent.subFeatureName = subFeatureName;
    }
    /**
     * End the pending tried event
     * @param {string} queryId The Augloop query ID
     * @param {ResultTypeEnum} resultType The result type of the tried event
     * @param {ICopilotAshaError} [ashaError] The ASHA error, if any
     */ endTriedEvent(queryId, resultType, ashaError) {
        // if there is no pending tried event, return
        if (this.pendingTriedEvent?.queryId !== queryId) {
            this.logContext?.traceLogger.logTrace(0x1e29a4ce /* tag_4k0to */ , TraceLevel.Error, `${this.name} - endTriedEvent called with unexpected queryId.`);
            return;
        }
        // end the QosEvent
        this.logTriedEvent(this.pendingTriedEvent);
        this.pendingTriedEvent.qosEvent.end({
            resultType,
            extraData: {
                queryId: queryId
            }
        });
        // log the ASHA error, if any
        if (ashaError) {
            this.logAshaEvent(this.pendingTriedEvent.featureName, ashaError);
        }
        this.pendingTriedEvent = undefined;
    }
    /**
     * Log a Kept event
     * @param {string} queryId The Augloop query ID
     */ logKeptEvent(queryId) {
        const telemetryData = this.getTelemetryDataFromQueryId(queryId);
        this.usageLogger?.logKept(telemetryData.featureName, telemetryData.subFeatureName, telemetryData.queryId);
    }
    /**
     * Log an Undo event
     * @param {string} queryId The Augloop query ID
     */ logUndoEvent(queryId) {
        const telemetryData = this.getTelemetryDataFromQueryId(queryId);
        this.usageLogger?.logRejected(telemetryData.featureName, telemetryData.subFeatureName, telemetryData.queryId);
    }
    /**
     * Log a Feedback button clicked event
     * @param {boolean} isThumbsUp Whether the user clicked the thumbs up button
     * @param {string} [telemetryDataJson] The telemetry data JSON string
     */ logFeedbackButtonClicked(isThumbsUp, telemetryDataJson) {
        // get the query ID from the telemetry data JSON string
        const queryId = this.getQueryIdFromTelemetryData(telemetryDataJson);
        // get the telemetry data associated with the query ID
        const telemetryData = queryId ? this.getTelemetryDataFromQueryId(queryId) : undefined;
        // log the feedback button clicked event
        const featureName = telemetryData?.featureName ?? CopilotFeatureName.CopilotChat;
        this.usageLogger?.logFeedback(isThumbsUp, featureName, telemetryData?.subFeatureName, queryId);
        // log the ASHA error if the user clicked thumbs down
        if (!isThumbsUp) {
            this.logAshaEvent(featureName, {
                veto: CopilotAshaVeto.UserRejectedOrCancelledResponse,
                error: CopilotAshaError.UserThumbsDown,
                inStaging: false
            });
        }
    }
    /**
     * Log an ASHA error
     * @param {CopilotFeatureName} featureName The feature name
     * @param {ICopilotAshaError} ashaError The ASHA error
     */ logAshaEvent(featureName, ashaError) {
        const pillar = this.getAshaPillar(featureName);
        const { veto, error, inStaging } = ashaError;
        const vetoWithPrefix = this.getAshaVetoWithPrefix(featureName, veto);
        this.ashaLogger?.logAshaError(pillar, vetoWithPrefix, error, inStaging);
    }
    /**
     * Log a Copilot chat message
     * @param {ICopilotTelemetryData} telemetryData The telemetry data
     */ logTriedEvent(telemetryData) {
        const { queryId, featureName, subFeatureName } = telemetryData;
        // skip logging if the query has already been logged
        if (this.queryTelemetryData.has(queryId)) {
            return;
        }
        // log the tried event
        this.queryTelemetryData.set(queryId, telemetryData);
        this.usageLogger?.logTried(featureName, subFeatureName, queryId);
    }
    /**
     * Get the ASHA pillar for the feature
     * @param {CopilotFeatureName} featureName The feature name
     */ getAshaPillar(featureName) {
        // Copilot ASHA pillar is in the format of "Copilot:Verb:Feature"
        const verb = CopilotFeatureToVerbMapping[featureName];
        return `Copilot:${verb}:${featureName}`;
    }
    /**
     * Get the ASHA veto with the feature name prefix
     * @param {CopilotFeatureName} featureName The feature name
     * @param {CopilotAshaVeto} veto The ASHA veto
     */ getAshaVetoWithPrefix(featureName, veto) {
        // Copilot ASHA veto is in the format of "Verb Feature Veto"
        const verb = CopilotFeatureToVerbMapping[featureName];
        return `${verb} ${featureName} ${veto}`;
    }
    /**
     * Get the scenarios from the ODSL statements
     * @param {OdslStatement[]} statements The ODSL statements
     * @return {*}  {Set<CopilotScenarioName>} The set of scenarios
     */ getScenariosFromOdslStatements(statements) {
        // map the ODSL function names to the Copilot scenario names
        const scenarios = statements.map((statement)=>OdslFunctionToScenarioMapping[statement.name]).filter((scenario)=>scenario != null);
        return new Set(scenarios);
    }
    /**
     * Get the telemetry data associated with the Augloop query request
     * @param {string} queryId The Augloop query ID
     * @return {*}  {ICopilotTelemetryData} The telemetry data
     */ getTelemetryDataFromQueryId(queryId) {
        // return the telemetry data associated with the query ID, or a default object
        return this.queryTelemetryData.get(queryId) ?? {
            queryId: queryId,
            featureName: CopilotFeatureName.CopilotChat
        };
    }
    /**
     * Get the query ID from the telemetry data JSON string
     * @param {string} [telemetryDataJson] The telemetry data JSON string
     * @return {*}  {(string | undefined)} The query ID
     */ getQueryIdFromTelemetryData(telemetryDataJson) {
        // telemetryDataJson is expected to be in the format of {"correlationId":"<queryId>"}
        if (!telemetryDataJson) {
            return;
        }
        try {
            // parse the telemetry data JSON string
            const parsedData = JSON.parse(telemetryDataJson);
            return parsedData.correlationId;
        } catch  {
            this.logContext?.traceLogger.logTrace(0x1e29a4cd /* tag_4k0tn */ , TraceLevel.Error, "Copilot getQueryIdFromTelemetryData: invalid telemetryDataJson");
        }
    }
    /**
     * Get the feature name from the scenarios
     * @param {Set<CopilotScenarioName>} scenarios The set of scenarios
     * @return {*}  {CopilotFeatureName} The feature name
     */ getFeatureNameFromScenarios(scenarios) {
        // determine the feature name based on the scenarios
        if (CreateEntitiesScenarios.some((scenario)=>scenarios.has(scenario))) {
            return CopilotFeatureName.CreateEntities;
        } else if (ModifyEntitiesScenarios.some((scenario)=>scenarios.has(scenario))) {
            return CopilotFeatureName.ModifyEntities;
        }
        return CopilotFeatureName.CopilotChat;
    }
    /** Private constructor to prevent instantiation */ constructor(){
        // The name of the class
        this.name = "CopilotTelemetryHandler";
        /** Telemetry data associated with the Augloop query request */ this.queryTelemetryData = new Map();
        /** Set of enabled features that have been logged */ this.loggedEnabledFeatures = new Set();
        /** Set of seen features that have been logged */ this.loggedSeenFeatures = new Set();
    }
}
