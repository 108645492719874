// Utilities
import { concatStyleSets } from "@fluentui/merge-styles";
import { memoizeFunction } from "@fluentui/react";
/**
 * Get styles for the keyword filter searchbox
 * @param customStyles Custom styles for the searchbox
 */ export const getSearchBoxStyles = memoizeFunction((customStyles)=>{
    const root = [
        {
            paddingTop: `1px`,
            width: `0px`,
            transition: `width .2s ease-in-out`,
            "&.expand": {
                width: `300px`
            },
            "&.collapse": {
                width: `0px`
            }
        }
    ];
    const iconContainer = {
        justifyContent: `flex-end`,
        paddingLeft: `0px`
    };
    const styles = {
        root,
        iconContainer
    };
    return concatStyleSets(styles, customStyles);
});
/**
 * Get styles for the keyword filter button
 * @param theme Current app's Fluentv8 theme
 * @param customStyles Custom styles for the button
 */ export const getIconButtonStyles = memoizeFunction((theme, customStyles)=>{
    const root = {
        color: theme.semanticColors.buttonText,
        width: `24px`,
        height: `24px`
    };
    const styles = {
        root
    };
    return concatStyleSets(styles, customStyles);
});
