import { Copilot } from "@microsoft/oteljs";
import { logEvent } from "@ms/uno-telemetry/lib/local/LoggingHelper";
import { EventType } from "@ms/uno-telemetry/lib/local/UTelLogHandler";
/**
 * Logs the event using UTelJs
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ function LogUTel(event, logHandler) {
    const dataField = Copilot.getFields(event);
    // Log the event
    logHandler.log({
        type: EventType.Copilot,
        payload: dataField
    });
}
/**
 * Logs the event with all available loggers
 * @param event Event to be logged
 * @param logHandler Log handler to log event
 */ export function Log(event, logHandler) {
    logEvent(event, LogUTel, logHandler);
}
