import { EventType } from "@ms/uno-telemetry/lib/local/UTelLogHandler";
/**
 * Class responsible for logging shared chat telemetry events.
 */ export class SharedChatLogger {
    /**
     * Logs a telemetry event if it should be logged.
     * @param telemetryLog - The telemetry log to be logged.
     */ LogTelemetryEvent(telemetryLog) {
        switch(telemetryLog.GetEventName()){
            case "ChatEvents":
                if (!telemetryLog.GetShouldLog()) {
                    break;
                }
                this.logSharedTelemetryEvent(telemetryLog);
                break;
            default:
                break;
        }
    }
    /**
     * Logs the shared telemetry event.
     * @param telemetryLog - The telemetry log to be logged.
     */ logSharedTelemetryEvent(telemetryLog) {
        this.logHandler.log({
            type: EventType.Copilot,
            payload: telemetryLog.GetLogs()
        });
    }
    /**
     * Constructs a new instance of SharedChatLogger.
     * @param logHandler - The log handler used to log telemetry events.
     */ constructor(logHandler){
        this.logHandler = logHandler;
    }
}
