// Constants
import { UncategorizedBucketId } from "@ms/uno-constants/lib/local/AppConstants";
// Controls
import { TaskCollectionView } from "./TaskCollectionView";
import { TaskAccessContext } from "@ms/uno-models/lib/local/client/legacyPlanner/task/TaskAccessContext";
import { Assignment } from "@ms/uno-models/lib/local/client/legacyPlanner/task/Assignment";
// Utilities
import { createTempTask } from "@ms/uno-models/lib/local/utilities/TaskUtilities";
/**
 * Base abstract class for all views that render tasks in a plan
 */ export class PlanTaskCollectionView extends TaskCollectionView {
    constructor(...args){
        super(...args), this.taskAccessContext = TaskAccessContext.Plan, /**
     * Delete the bucket
     * @param bucketId Bucket to delete
     */ this.onDeleteBucket = async (bucketId)=>{
            const { actionCreatorProviders, viewPartitionKey: planId } = this.props;
            const { basicBucketStore } = this.state;
            if (!basicBucketStore) {
                return;
            }
            const bucketActionCreator = await actionCreatorProviders.basicBucket();
            if (bucketId === "null") {
                // Unbucketed column
                bucketActionCreator.deleteBucket(`${UncategorizedBucketId}${planId}`, "");
            } else {
                const bucket = basicBucketStore.getBucket(bucketId);
                bucketActionCreator.deleteBucket(bucketId, bucket.data ? bucket.data.itemVersion : "");
            }
        }, /**
     * Assign a task to a user
     * @param userId User to add
     * @param taskId Task to add user to
     * @param elementToFocusOnDismiss HTML element to focus on when dismissing warning dialog
     * @param onAssignSuccess Callback for when the task assignment is successful
     */ this.onAssignTask = async (userId, taskId, elementToFocusOnDismiss, onAssignSuccess)=>{
            const { viewPartitionKey: planId } = this.props;
            const { appContextStore, basicTaskStore } = this.state;
            const currentUserId = appContextStore?.getAppContext().userId;
            if (currentUserId == null || currentUserId === "") {
                throw new Error("currentUserId is null or empty");
            }
            if (userId == null || userId === "") {
                throw new Error("userId is null or empty");
            }
            let originalTask = basicTaskStore?.getTask(taskId)?.data ?? null;
            if (originalTask == null) {
                // Construct a temp task with task data to pass to the taskAC
                // This is still a valid case for the AddTaskCard scenario where the task doesn't exist in the store
                originalTask = createTempTask(taskId, planId);
            }
            const updatedTask = originalTask.getClone();
            if (updatedTask) {
                updatedTask.assignments[userId] = new Assignment(currentUserId, new Date());
            }
            const taskActionCreator = await this.props.actionCreatorProviders.basicTask();
            taskActionCreator.assignTask({
                userId,
                originalTask,
                updatedTask,
                elementToFocusOnDismiss,
                onAssignSuccess
            });
        };
    }
}
