import { Asha } from "@microsoft/oteljs";
import { Log } from "../events/Asha.event";
/**
 * The logger for Asha errors
 */ export class AshaLogger {
    /**
     * Logs an Asha error
     * @param {string} pillar The pillar of the error
     * @param {string} veto The veto of the error
     * @param {string} error The error message
     * @param {boolean} inStaging Whether the error occurred in staging
     */ logAshaError(pillar, veto, error, inStaging) {
        // Create the event
        const event = {
            eventType: Asha.EventType.Error,
            pillar,
            veto,
            error,
            inStaging
        };
        Log(event, this.logHandler);
    }
    /**
     * Creates an instance of AshaLogger.
     * @param {ILogHandler} logHandler The log handler to log the event
     */ constructor(logHandler){
        this.logHandler = logHandler;
    }
}
