// Constants
import { Colors } from "@ms/uno-constants/lib/local/Colors";
// Models
import { UnoTheme } from "@ms/uno-models/lib/local/theme/UnoTheme";
// Styles
import { flexParent } from "../../utilities/Mixin.styles";
// Utilities
import { FontWeights } from "@fluentui/react";
import { highContrastHighlightColor, highContrastSelectionText } from "../../utilities/HighContrast.styles";
/**
 * Styles for the fluent Pivot in the pill pivot component
 * @param props The style props of the Pivot
 */ export const getPillPivotStyles = (props)=>{
    const { theme: { palette, semanticColors, name } } = props;
    return {
        root: [
            flexParent("row", "nowrap")
        ],
        link: {
            height: "28px",
            lineHeight: "28px",
            padding: "0px",
            marginBottom: "4px",
            marginTop: "4px",
            ":hover, :focus": {
                backgroundColor: "transparent",
                "> span > div > .ms-Pivot-linkContent": [
                    highContrastHighlightColor(),
                    {
                        backgroundColor: semanticColors.buttonBackgroundHovered,
                        borderColor: palette.neutralTertiaryAlt,
                        color: semanticColors.buttonTextHovered
                    }
                ]
            },
            ":active": {
                backgroundColor: "transparent",
                "> span > div > .ms-Pivot-linkContent": {
                    backgroundColor: semanticColors.buttonBackgroundChecked,
                    borderColor: palette.neutralTertiaryAlt
                }
            }
        },
        linkContent: [
            {
                borderRadius: "14px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: name === UnoTheme.TeamsContrast ? Colors.White : palette.neutralQuaternary
            }
        ],
        linkIsSelected: [
            {
                color: semanticColors.accentButtonText,
                fontWeight: FontWeights.regular,
                padding: "0px",
                "> span > div > .ms-Pivot-linkContent": [
                    highContrastSelectionText(),
                    {
                        backgroundColor: name === UnoTheme.TeamsContrast ? Colors.HighContrastCyan : semanticColors.primaryButtonBackground,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: name === UnoTheme.TeamsContrast ? Colors.HighContrastCyan : semanticColors.primaryButtonBackground
                    }
                ],
                "::before": {
                    backgroundColor: "transparent",
                    transition: "none"
                },
                ":hover, :focus": [
                    {
                        backgroundColor: "transparent",
                        "> span > div > .ms-Pivot-linkContent": [
                            highContrastSelectionText(),
                            {
                                color: semanticColors.accentButtonText,
                                backgroundColor: semanticColors.primaryButtonBackgroundHovered,
                                borderColor: semanticColors.primaryButtonBackgroundHovered
                            }
                        ]
                    }
                ],
                ":active > span > div > .ms-Pivot-linkContent": {
                    color: semanticColors.accentButtonText,
                    backgroundColor: semanticColors.primaryButtonBackgroundPressed,
                    borderColor: semanticColors.primaryButtonBackgroundPressed
                }
            }
        ],
        text: {
            padding: "0px 12px"
        }
    };
};
/** Custom styles for the pivot link wrapper div element */ export const pivotLinkWrapperDivStyles = {
    flex: "0 0 auto",
    display: "flex"
};
