import { PlannerSkillOrigin } from "@augloop-types/planner-app-copilot";
export var CopilotFeatureName;
(function(CopilotFeatureName) {
    /** Represents the action to build a plan. */ CopilotFeatureName["BuildPlan"] = "Build Plan";
    /** Represents the action to create entities. */ CopilotFeatureName["CreateEntities"] = "Create Entities";
    /** Represents the action to modify entities. */ CopilotFeatureName["ModifyEntities"] = "Modify Entities";
    /** Represents the action for chat QnA. */ CopilotFeatureName["ChatQnA"] = "Chat QnA";
    /** Represents the action for task prioritization. */ CopilotFeatureName["TaskPrioritization"] = "Task Prioritization";
    /** Represents the action for Copilot chat. */ CopilotFeatureName["CopilotChat"] = "CopilotChat";
})(CopilotFeatureName || (CopilotFeatureName = {}));
export var CopilotTelemetryVerb;
(function(CopilotTelemetryVerb) {
    /** Represents the create action. */ CopilotTelemetryVerb["Create"] = "Create";
    /** Represents the command action. */ CopilotTelemetryVerb["Command"] = "Command";
    /** Represents the ask action. */ CopilotTelemetryVerb["Ask"] = "Ask";
    /** Represents an unclear intent action. */ CopilotTelemetryVerb["UnclearIntent"] = "Unclear Intent";
})(CopilotTelemetryVerb || (CopilotTelemetryVerb = {}));
/**
 * Mapping of CopilotFeatureName to CopilotTelemetryVerb
 */ export const CopilotFeatureToVerbMapping = {
    ["Build Plan"]: "Create",
    ["Create Entities"]: "Create",
    ["Modify Entities"]: "Command",
    ["Chat QnA"]: "Ask",
    ["Task Prioritization"]: "Ask",
    ["CopilotChat"]: "Unclear Intent"
};
/**
 * Mapping of PlannerSkillOrigin to CopilotFeatureName
 */ export const CopilotContentOriginToFeatureMapping = {
    [PlannerSkillOrigin.SearchTaskPriorities]: "Task Prioritization",
    [PlannerSkillOrigin.SearchPlan]: "Chat QnA",
    [PlannerSkillOrigin.BuildPlanOutline]: "Build Plan",
    [PlannerSkillOrigin.EditPlan]: "Create Entities",
    /** Maps deep leo skill origin to chat qna feature */ DeepLeo: "Chat QnA"
};
export var CopilotScenarioName;
(function(CopilotScenarioName) {
    /** Represents the action to create tasks. */ CopilotScenarioName["CreateTasks"] = "Create Tasks";
    /** Represents the action to create buckets. */ CopilotScenarioName["CreateBuckets"] = "Create Buckets";
    /** Represents the action to create goals. */ CopilotScenarioName["CreateGoals"] = "Create Goals";
    /** Represents the action to update tasks. */ CopilotScenarioName["UpdateTasks"] = "Update Tasks";
    /** Represents the action to update buckets. */ CopilotScenarioName["UpdateBuckets"] = "Update Buckets";
    /** Represents the action to update goals. */ CopilotScenarioName["UpdateGoals"] = "Update Goals";
    /** Represents the action to delete tasks. */ CopilotScenarioName["DeleteTasks"] = "Delete Tasks";
    /** Represents the action to delete buckets. */ CopilotScenarioName["DeleteBuckets"] = "Delete Buckets";
    /** Represents the action to delete goals. */ CopilotScenarioName["DeleteGoals"] = "Delete Goals";
})(CopilotScenarioName || (CopilotScenarioName = {}));
/**
 * List of scenarios that fall under CopilotFeatureName.CreateEntities
 */ export const CreateEntitiesScenarios = [
    "Create Tasks",
    "Create Buckets",
    "Create Goals"
];
/**
 * List of scenarios that fall under CopilotFeatureName.ModifyEntities
 */ export const ModifyEntitiesScenarios = [
    "Update Tasks",
    "Update Buckets",
    "Update Goals",
    "Delete Tasks",
    "Delete Buckets",
    "Delete Goals"
];
/**
 * List of scenarios that fall under CopilotFeatureName.BuildPlan
 */ export const OdslFunctionToScenarioMapping = {
    /** Maps insert tasks action to CreateTasks scenario. */ insertTasks: "Create Tasks",
    /** Maps insert buckets action to CreateBuckets scenario. */ insertBuckets: "Create Buckets",
    /** Maps insert goals action to CreateGoals scenario. */ insertGoals: "Create Goals",
    /** Maps delete tasks action to DeleteTasks scenario. */ deleteTasks: "Delete Tasks",
    /** Maps delete buckets action to DeleteBuckets scenario. */ deleteBuckets: "Delete Buckets",
    /** Maps delete goals action to DeleteGoals scenario. */ deleteGoals: "Delete Goals",
    /** Maps format tasks action to UpdateTasks scenario. */ formatTasks: "Update Tasks",
    /** Maps format buckets action to UpdateBuckets scenario. */ formatBuckets: "Update Buckets",
    /** Maps format goals action to UpdateGoals scenario. */ formatGoals: "Update Goals"
};
export var CopilotAshaVeto;
(function(CopilotAshaVeto) {
    CopilotAshaVeto["RAIError"] = "Responsible AI Filter Blocked Response";
    CopilotAshaVeto["LLMServiceIssue"] = "LLM Service Side Issue Prevents Output";
    CopilotAshaVeto["UnsupportedFunctionality"] = "Unsupported Copilot Functionality";
    CopilotAshaVeto["ResponseDelayedOrTimedOut"] = "Response delayed or timed out";
    CopilotAshaVeto["UnableToCompleteTask"] = "Unable to complete task";
    CopilotAshaVeto["UserAccessIssue"] = "User access issue for Copilot";
    CopilotAshaVeto["UserInputIssue"] = "User input could not be processed";
    CopilotAshaVeto["UserRejectedOrCancelledResponse"] = "User Rejected or Cancelled Response";
    CopilotAshaVeto["CapacityIssue"] = "Unable to generate response due to capacity issue";
    CopilotAshaVeto["UIFailedToUpdateOrLoad"] = "UI Failed to Update or Load";
    CopilotAshaVeto["UnclassifiedError"] = "Unclassified ASHA Error";
})(CopilotAshaVeto || (CopilotAshaVeto = {}));
export var CopilotAshaError;
(function(CopilotAshaError) {
    CopilotAshaError["InputQueryTooLong"] = "Input query is too long";
    CopilotAshaError["InputQueryTooShort"] = "Input query is too short";
    CopilotAshaError["InputQueryInvalid"] = "Input query is invalid";
    CopilotAshaError["InputQueryNotSupported"] = "Input query is not supported";
    CopilotAshaError["InputQueryBadToken"] = "Input query contains invalid auth token";
    CopilotAshaError["InputQueryLowTranslationScore"] = "Input query has low text translation score";
    CopilotAshaError["InputQueryUnsupportedLanguage"] = "Input query contains unsupported language";
    CopilotAshaError["InputQueryUnknwonLanguage"] = "Input query contains unknown language";
    CopilotAshaError["OffensiveInputQuery"] = "Offensive content detected in input query";
    CopilotAshaError["OffensiveInputContent"] = "Offensive content detected in input content";
    CopilotAshaError["OffensiveGeneratedOutput"] = "Offensive content detected in generated output";
    CopilotAshaError["OffensiveGeneratedSydneyOutput"] = "Offensive content detected in generated Sydney output";
    CopilotAshaError["SystemThrottled"] = "Server is overwhelmed with activity or occupied";
    CopilotAshaError["ScenarioThrottled"] = "Scenario has issued too many requests to the server";
    CopilotAshaError["UserThrottled"] = "User has issued too many requests to the server";
    CopilotAshaError["ServerNotAvailable"] = "Error occurred because server is unavailable";
    CopilotAshaError["ServerTimeout"] = "Timeout waiting for server";
    CopilotAshaError["RequestTooLarge"] = "Request to Copilot was too large";
    CopilotAshaError["InvocationCanceled"] = "Invocation canceled due to the underlying connection being closed";
    CopilotAshaError["ODSLExecutionFailure"] = "ODSL was successfully returned, but the client failed to execute it";
    CopilotAshaError["ODSLExecutionNoChanges"] = "ODSL was successfully returned, but the client execution did not result in any changes";
    CopilotAshaError["ODSLExecutionTimeout"] = "Timeout occurred during program execution";
    CopilotAshaError["UserAbortedQueryExecution"] = "User aborted query execution";
    CopilotAshaError["UserThumbsDown"] = "User reacted thumbs down to chat response";
    CopilotAshaError["SydneyDisengagement"] = "Sydney disengagement error";
    CopilotAshaError["SydneyConnectionFailed"] = "Sydney websocket connection failed";
    CopilotAshaError["SydneyError"] = "Error occurred in Sydney";
    CopilotAshaError["Licensing"] = "Error occurred because copilot license couldn't be validated";
    CopilotAshaError["ODSLSyntaxDismissedInvalidRequiredArguments"] = "ODSL statement validation failed due to missing required arguments after dismissing";
    CopilotAshaError["ODSLSyntaxMissingRequiredArguments"] = "ODSL statement validation failed due to missing required arguments";
    CopilotAshaError["ODSLSyntaxUnexpected"] = "ODSL statement validation failed due to unexpected error";
    CopilotAshaError["ODSLSyntaxUnknownStatement"] = "ODSL statement validation failed due to unknown statement";
    CopilotAshaError["ODSLSyntaxUnsupported"] = "ODSL statement validation failed due to unsupported argument";
    CopilotAshaError["ODSLSyntaxUnsupportedByAdmin"] = "ODSL statement validation failed due to statement unsupported by admin";
    CopilotAshaError["ODSLSyntaxVariableUnknownName"] = "ODSL statement validation failed due to unknwon variable name";
    CopilotAshaError["ODSLSyntaxVariableWrongType"] = "ODSL statement validation failed due to wrong variable type";
    CopilotAshaError["ChatHistoryHydrationFailed"] = "Failed to hydrate previous chat history";
    CopilotAshaError["SkillFailedEditPlan"] = "Skill failed: edit_plan";
    CopilotAshaError["JsonParsingFailure"] = "Failed to parse JSON returned by LLM during post processing";
    CopilotAshaError["InvalidGenerateStatement"] = "The statement generated by LLM is not valid";
    CopilotAshaError["UnknownError"] = "Error occurred because of an unclassified issue";
})(CopilotAshaError || (CopilotAshaError = {}));
