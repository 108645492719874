// Constants
import { TaskAccessContext } from "@ms/uno-models/lib/local/client/legacyPlanner/task/TaskAccessContext";
import { AllUserTasksGridViewName } from "../GridViewConstants";
import { StoreEntityStatus } from "@ms/uno-constants/lib/local/StoreConstants";
// Controls
import { UserTasksGridView } from "../UserTasksGridView";
import { MruApp } from "@ms/uno-models/lib/local/service/mru/MruApp";
import { ContainerType } from "@ms/uno-models/lib/local/service/graph-legacy/container/ContainerType";
// Utilities
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import uniq from "lodash/uniq";
/**
 * AllUserTasks grid view control
 */ export class AllUserTasksGridView extends UserTasksGridView {
    name() {
        return AllUserTasksGridViewName;
    }
    getCreationDefaults() {
        let updates = {};
        // Set default plan id if it is available, and no other plan has been explicitly selected
        const { basicPlanStore } = this.state;
        const { storeProviders } = this.props;
        const defaultPlanId = basicPlanStore?.getDefaultPlanId() ?? null;
        const isTodoNotLicensed = storeProviders.appContext().getTodoSettings().status === StoreEntityStatus.UnableToFetch;
        const isPlanAlreadySelected = this.state.newTask.planId && !LocalIdGenerator.isLocalTempId(this.state.newTask.planId);
        if (!isPlanAlreadySelected) {
            // Select defaultPlanId if it's available
            if (defaultPlanId) {
                updates = {
                    ...updates,
                    planId: defaultPlanId
                };
            } else if (isTodoNotLicensed) {
                const planPickerOptions = this.getPlanPickerOptions().all;
                if (planPickerOptions.length > 0) {
                    updates = {
                        ...updates,
                        planId: planPickerOptions[0].id
                    };
                }
            }
        }
        return updates;
    }
    /**
     * Helper to get plan options for the plan column
     */ getPlanPickerOptions() {
        let planOptions = [];
        const { basicPlanStore } = this.state;
        const defaultPlanId = basicPlanStore?.getDefaultPlanId() ?? null;
        if (defaultPlanId) {
            planOptions.unshift(defaultPlanId);
        }
        const defaultOption = defaultPlanId ? this.getPlanOptionInfo(defaultPlanId) : null;
        //Planner based plans support task creation in MyDay
        const pinnedPlanOptions = this.getPinnedPlanOptions((item)=>item.app === MruApp.Planner).map((plan)=>plan.id);
        const recentPlanOptions = this.getRecentPlanOptions((item)=>item.app === MruApp.Planner).map((plan)=>plan.id);
        const teamsPlanOptions = this.getTeamsPlanOptions();
        const allPlanOptions = this.getAllPlanOptions([
            ContainerType.Group,
            ContainerType.User,
            ContainerType.ToDo
        ]);
        Array.prototype.push.apply(planOptions, pinnedPlanOptions);
        Array.prototype.push.apply(planOptions, recentPlanOptions);
        Array.prototype.push.apply(planOptions, teamsPlanOptions);
        Array.prototype.push.apply(planOptions, allPlanOptions);
        // Flagged email plan is not a valid task creation target
        const flaggedEmailPlanId = basicPlanStore?.getFlaggedEmailPlanId() ?? null;
        if (flaggedEmailPlanId) {
            planOptions = planOptions.filter((option)=>option !== flaggedEmailPlanId);
        }
        const uniquePlanIds = uniq(planOptions);
        return {
            all: uniquePlanIds.map((planId)=>this.getPlanOptionInfo(planId)),
            pinned: pinnedPlanOptions.map((planId)=>this.getPlanOptionInfo(planId)),
            recent: recentPlanOptions.map((planId)=>this.getPlanOptionInfo(planId)),
            defaultOption
        };
    }
    /**
     * Initializes a new instance of the AllUserTasksGridView
     * @param props Props for the AllUserTasksGridView
     * @param [context] Context to pass onto base component
     */ constructor(props, context){
        super(props, context), this.enableTaskCreation = true, this.taskAccessContext = TaskAccessContext.AssignedToUser;
    }
}
