// Constants
import { TaskAccessContext } from "@ms/uno-models/lib/local/client/legacyPlanner/task/TaskAccessContext";
import { AssignedToUserGridViewName } from "../GridViewConstants";
// Controls
import { UserTasksGridView } from "../UserTasksGridView";
import { Assignment } from "@ms/uno-models/lib/local/client/legacyPlanner/task/Assignment";
import { MruApp } from "@ms/uno-models/lib/local/service/mru/MruApp";
import { ContainerType } from "@ms/uno-models/lib/local/service/graph-legacy/container/ContainerType";
// Utilities
import { LocalIdGenerator } from "@ms/uno-utilities/lib/local/LocalIdGenerator";
import uniq from "lodash/uniq";
/**
 * AssignedToUser grid view control
 */ export class AssignedToUserGridView extends UserTasksGridView {
    name() {
        return AssignedToUserGridViewName;
    }
    /**
     *
     * Helper to return default values for task creation in ATM view
     */ getCreationDefaults() {
        let updates = {};
        const userId = this.props.viewPartitionKey;
        if (!this.state.newTask.assignments?.[userId]) {
            updates = {
                assignments: {
                    [userId]: new Assignment(userId)
                }
            };
        }
        // Select the first plan in the list if no plan is already selected
        const planPickerOptions = this.getPlanPickerOptions();
        const pinnedPlanOptions = planPickerOptions.pinned;
        const recentPlanOptions = planPickerOptions.recent;
        // Default plan should be first plan in pinned or first plan in recent if pinned is empty
        const defaultPlanId = pinnedPlanOptions[0]?.id ?? recentPlanOptions[0]?.id;
        const isPlanAlreadySelected = this.state.newTask.planId && !LocalIdGenerator.isLocalTempId(this.state.newTask.planId);
        if (defaultPlanId && !isPlanAlreadySelected) {
            updates = {
                ...updates,
                planId: defaultPlanId
            };
        }
        return updates;
    }
    /**
     * Helper to get plan options for the plan picker dropdown
     */ getPlanPickerOptions() {
        /**
         * PBI# 8841823
         * Even though we don't necessarily needed to enable task addition for ATM view,
         * we run into an unexpected bug when task addition is disabled. Grid fails to
         * render certain rows unless the component is re-mounted.
         */ // ATM tasks can only be created in Planner based plans
        const planOptions = [];
        const pinnedPlanOptions = this.getPinnedPlanOptions((item)=>item.app === MruApp.Planner).map((plan)=>plan.id);
        const recentPlanOptions = this.getRecentPlanOptions((item)=>item.app === MruApp.Planner).map((plan)=>plan.id);
        const teamsPlanOptions = this.getTeamsPlanOptions();
        const allPlanOptions = this.getAllPlanOptions([
            ContainerType.Group,
            ContainerType.User
        ]);
        Array.prototype.push.apply(planOptions, pinnedPlanOptions);
        Array.prototype.push.apply(planOptions, recentPlanOptions);
        Array.prototype.push.apply(planOptions, teamsPlanOptions);
        Array.prototype.push.apply(planOptions, allPlanOptions);
        const uniquePlanIds = uniq(planOptions);
        return {
            all: uniquePlanIds.map((planId)=>this.getPlanOptionInfo(planId)),
            pinned: pinnedPlanOptions.map((planId)=>this.getPlanOptionInfo(planId)),
            recent: recentPlanOptions.map((planId)=>this.getPlanOptionInfo(planId)),
            defaultOption: null
        };
    }
    /**
     * Initializes a new instance of the AssignedToUserGridView
     * @param props Props for the AssignedToUserGridView
     * @param [context] Context to pass onto base component
     */ constructor(props, context){
        super(props, context), this.enableTaskCreation = true, this.taskAccessContext = TaskAccessContext.AssignedToUser;
    }
}
