import { Strings } from "@ms/uno-resources/lib/local/CultureUtilities";
import { myDayRegularIconProps } from "../icons/icons.styles";
import { GridContextMenuAddToMyDay, GridContextMenuRemoveFromMyDay } from "@ms/uno-constants/lib/local/TaskGridConstants";
/**
 * Get the context menu item for adding or removing a task from My Day
 * @param isOnMyDay - Whether the task is on My Day
 * @param onClick - The callback to be called when the item is clicked
 * @returns The context menu item
 */ export const getMyDayContextMenuItem = (isOnMyDay, onClick)=>{
    if (isOnMyDay) {
        return {
            key: GridContextMenuRemoveFromMyDay,
            name: Strings.TaskContextMenu_RemoveFromMyDay,
            iconProps: myDayRegularIconProps,
            title: Strings.TaskContextMenu_RemoveFromMyDayTooltip,
            onClick
        };
    } else {
        return {
            key: GridContextMenuAddToMyDay,
            name: Strings.TaskContextMenu_AddToMyDay,
            iconProps: myDayRegularIconProps,
            title: Strings.TaskContextMenu_AddToMyDayTooltip,
            onClick
        };
    }
};
