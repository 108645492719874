// Constants
import { TaskAccessContext } from "@ms/uno-models/lib/local/client/legacyPlanner/task/TaskAccessContext";
import { MyTasksBasicPlanTasksGridViewName } from "../GridViewConstants";
// Controls
import { BasicPlanTasksGridView } from "../basicPlan/BasicPlanTasksGridView";
/**
 * My Tasks BasicPlanTasksGridView
 * Some views in My Tasks (Private tasks, Flagged emails) load Basic Plan Grid view, but should have some ATM data
 */ export class MyTasksBasicPlanTasksGridView extends BasicPlanTasksGridView {
    name() {
        return MyTasksBasicPlanTasksGridViewName;
    }
    /**
     * Initializes a new instance of the MyTasksBasicPlanTasksGridView
     * @param props Props for the MyTasksBasicPlanTasksGridView
     * @param [context] Context to pass onto base component
     */ constructor(props, context){
        super(props, context), // Override task access context as it should not be Plan context
        this.taskAccessContext = TaskAccessContext.AssignedToUser;
    }
}
