import { Copilot } from "@microsoft/oteljs";
import { Log } from "../events/Copilot.event";
/**
 * Logger for Copilot usage events
 * @export CopilotUsageLogger class
 * @class CopilotUsageLogger
 * @implements {ICopilotUsageLogger}
 */ export class CopilotUsageLogger {
    /**
     * Logs when a feature is enabled.
     * @param {CopilotFeatureName} featureName - The name of the feature.
     */ logEnabled(featureName) {
        const event = {
            featureActionType: Copilot.FeatureActionType.IsEnabled,
            featureName: featureName
        };
        Log(event, this.logHandler);
    }
    /**
     * Logs when a feature is seen.
     * @param {CopilotFeatureName} featureName - The name of the feature.
     */ logSeen(featureName) {
        const event = {
            featureActionType: Copilot.FeatureActionType.IsSeen,
            isUserInitiated: true,
            featureName: featureName,
            entryPoint: Copilot.EntryPoint.Chat
        };
        Log(event, this.logHandler);
    }
    /**
     * Logs when a feature is tried.
     * @param {CopilotFeatureName} featureName - The name of the feature.
     * @param {string} [subFeatureName] - The name of the sub-feature.
     * @param {string} [interactionId] - The interaction ID.
     */ logTried(featureName, subFeatureName, interactionId) {
        const event = {
            featureActionType: Copilot.FeatureActionType.IsTried,
            isUserInitiated: true,
            featureName: featureName,
            subFeatureName: subFeatureName,
            entryPoint: Copilot.EntryPoint.Chat,
            interactionId: interactionId
        };
        Log(event, this.logHandler);
    }
    /**
     * Logs when a feature is kept.
     * @param {CopilotFeatureName} featureName - The name of the feature.
     * @param {string} [subFeatureName] - The name of the sub-feature.
     * @param {string} [interactionId] - The interaction ID.
     */ logKept(featureName, subFeatureName, interactionId) {
        const event = {
            featureActionType: Copilot.FeatureActionType.IsKept,
            isUserInitiated: true,
            featureName: featureName,
            subFeatureName: subFeatureName,
            entryPoint: Copilot.EntryPoint.Chat,
            interactionId: interactionId
        };
        Log(event, this.logHandler);
    }
    /**
     * Logs when a feature is rejected.
     * @param {CopilotFeatureName} featureName - The name of the feature.
     * @param {string} [subFeatureName] - The name of the sub-feature.
     * @param {string} [interactionId] - The interaction ID.
     */ logRejected(featureName, subFeatureName, interactionId) {
        const event = {
            featureActionType: Copilot.FeatureActionType.IsRejected,
            isUserInitiated: true,
            featureName: featureName,
            subFeatureName: subFeatureName,
            entryPoint: Copilot.EntryPoint.Chat,
            interactionId: interactionId
        };
        Log(event, this.logHandler);
    }
    /**
     * Logs user feedback.
     * @param {boolean} isThumbsUp - Indicates if the feedback is positive (thumbs up).
     * @param {CopilotFeatureName} featureName - The name of the feature.
     * @param {string} [subFeatureName] - The name of the sub-feature.
     * @param {string} [interactionId] - The interaction ID.
     */ logFeedback(isThumbsUp, featureName, subFeatureName, interactionId) {
        const event = {
            isThumbsUp,
            isUserInitiated: true,
            featureName: featureName,
            subFeatureName: subFeatureName,
            entryPoint: Copilot.EntryPoint.Chat,
            interactionId: interactionId
        };
        Log(event, this.logHandler);
    }
    /**
     * Creates an instance of CopilotUsageLogger.
     * @param {ILogHandler} logHandler
     */ constructor(logHandler){
        this.logHandler = logHandler;
    }
}
