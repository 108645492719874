// Constants
import { MyTasksViewPivotType } from "@ms/uno-constants/lib/local/ViewPivots";
import { MyPlansPagePivotType } from "@ms/uno-constants/lib/local/ViewPivots";
// Utilities
import { Strings } from "./CultureUtilities";
/**
 * Gets MyPlansPage pivot string for the given pivot type
 * @param pivotType Pivot type
 */ export function getMyPlansPivotString(pivotType) {
    switch(pivotType){
        case MyPlansPagePivotType.Shared:
            return Strings.MyPlansHeader_SharedPivotItemTitle;
        case MyPlansPagePivotType.Personal:
            return Strings.MyPlansHeader_PersonalPivotItemTitle;
        case MyPlansPagePivotType.Pinned:
            return Strings.MyPlansHeader_PinnedPivotItemTitle;
        case MyPlansPagePivotType.MyTeams:
            return Strings.MyPlansHeader_MyTeamsPivotItemTitle;
        case MyPlansPagePivotType.Recommended:
            return Strings.MyPlansHeader_RecommendedPivotItemTitle;
        case MyPlansPagePivotType.Recent:
        default:
            return Strings.MyPlansHeader_RecentPivotItemTitle;
    }
}
/**
 * Gets MyTasksPage pivot string for the given pivot type
 * @param pivotType Pivot type
 */ export function getMyTasksPivotString(pivotType) {
    switch(pivotType){
        case MyTasksViewPivotType.All:
            return Strings.MyTasksPage_AllTasksPivotItemTitle;
        case MyTasksViewPivotType.FlaggedEmails:
            return Strings.MyTasksPage_FlaggedEmailsPivotItemTitle;
        case MyTasksViewPivotType.PrivateTasks:
            return Strings.MyTasksPage_PrivateTasksPivotItemTitle;
        case MyTasksViewPivotType.AssignedToMe:
        default:
            return Strings.MyTasksPage_AssignedToMePivotItemTitle;
    }
}
