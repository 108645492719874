// Styles
import { flexParent, flexParentCenterAll } from "../../../utilities/Mixin.styles";
import { FontSizes, FontWeights, concatStyleSetsWithProps, memoizeFunction, mergeStyles } from "@fluentui/react";
/**
 * Helper to get styles for EmptyStateView
 * @param theme The fluent v8 theme
 * @param customStyles Custom styles to override default EmptyStateView styles
 */ export const getStyles = memoizeFunction((theme, customStyles)=>{
    const { palette } = theme;
    const root = [
        flexParent("column"),
        flexParentCenterAll,
        {
            marginTop: "78px",
            textAlign: "center"
        }
    ];
    const title = {
        fontSize: "16px",
        fontWeight: FontWeights.semibold,
        lineHeight: "22px",
        marginTop: "8px",
        color: palette.neutralPrimary
    };
    const imageContainer = [
        {
            maxWidth: "370px"
        }
    ];
    const description = {
        fontWeight: FontWeights.regular,
        lineHeight: "20px",
        marginTop: "8px",
        color: palette.neutralSecondary,
        maxWidth: "370px"
    };
    const subComponentStyles = {
        actionButton: getActionButtonStyles(theme)
    };
    const emptyStateViewStyles = {
        root,
        title,
        imageContainer,
        description,
        subComponentStyles
    };
    return concatStyleSetsWithProps(undefined, emptyStateViewStyles, customStyles);
});
/**
 * Get styles for the action button
 * @param theme The app theme
 */ const getActionButtonStyles = memoizeFunction((theme)=>{
    const { semanticColors } = theme;
    const actionButton = {
        root: {
            width: "auto",
            marginTop: "24px",
            borderRadius: "6px",
            backgroundColor: semanticColors.primaryButtonBackground,
            paddingLeft: "20px",
            ":focus": {
                outline: `2px solid ${semanticColors.focusBorder}`
            }
        },
        textContainer: {
            padding: "6px 20px 6px 4px"
        },
        label: {
            color: semanticColors.primaryButtonText,
            lineHeight: "20px",
            fontSize: FontSizes.medium,
            fontWeight: FontWeights.semibold
        },
        icon: {
            // Use !important to override the color that is directly set on the icon element
            color: semanticColors.primaryButtonText + "!important",
            marginLeft: "10px"
        }
    };
    return actionButton;
});
/**
 * Get the classnames associated with the computed styles for EmptyStateView
 * @param styles The styles for EmptyStateView component
 */ export const getClassNames = memoizeFunction((styles)=>({
        root: mergeStyles("emptyStateView", styles.root),
        title: mergeStyles("emptyStateTitle", styles.title),
        imageContainer: mergeStyles("emptyStateImageContainer", styles.imageContainer),
        description: mergeStyles("emptyStateDescription", styles.description)
    }));
